import React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { TbTransform, TbUserCog } from "react-icons/tb";
import { RiLockPasswordLine } from "react-icons/ri";
import { AiOutlineLogout } from "react-icons/ai";
import { RiDashboardFill } from "react-icons/ri";
import { MdSupportAgent, MdNotificationsNone } from "react-icons/md";
import { GiNewspaper } from "react-icons/gi";
import { PiVideoDuotone } from "react-icons/pi";

function Sidebar({ location, history }) {

  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const onClickMenu = (url) => {
    history.push(url);
    setTimeout(() => {
      setNavbarOpen(!navbarOpen)
    }, 150);
  }

  const logout = (url) => {
    window.sessionStorage.removeItem('user');
    history.push(url);
    setTimeout(() => {
      setNavbarOpen(!navbarOpen)
    }, 150);
  }

  const logoutSubmit = () => {
    confirmAlert({
      title: 'Are you sure you want to logout?',
      buttons: [
        {
          label: 'Logout',
          onClick: () => logout('/login')
        },
        {
          label: 'Cancel'
        }
      ],
      overlayClassName: 'custom-overlay'
    });
  }

  return (
    <>

      <button className={` ${navbarOpen === true ? 'hidden' : 'flex'} absolute top-0 left-0 px-5 items-center justify-center  text-gray-100 focus:outline-none focus:bg-gray-800 focus:text-gray-600 z-50 h-16 lg:hidden `} onClick={() => setNavbarOpen(!navbarOpen)}>
        <svg className="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7">
          </path>
        </svg>
      </button>

      {navbarOpen ?
        <div className={` ${navbarOpen === false ? 'hidden' : ''} lg:hidden  lg:flex-shrink-0 lg:static inset-0 z-50 fixed bg-opacity-80`} onClick={() => setNavbarOpen(!navbarOpen)}>
          <div className="flex items-center justify-center h-8 w-8 rounded-full focus:outline-none focus:bg-gray-600 bg-[#1C2745] absolute left-[260px] lg:left-80 top-2 z-50" aria-label="Close sidebar">
            <svg className="w-6 h-6 text-white z-50" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12">
              </path>
            </svg>
          </div>
        </div>
        : null}

      {/* Main Navbar */}
      <div className={` ${navbarOpen === false ? 'hidden' : 'flex'} fixed left-0 inset-y-0 lg:static lg:left-auto lg:inset-auto lg:flex lg:w-80 w-64 z-50 lg:m-10 m-4 bg-opacity-40 lg:bg-transparent bg-black`}>

        <div className="relative flex flex-col w-64 overflow-y-auto lg:w-80 bg-right-top rounded-[14px] bg-no-repeat">
          <div className="flex flex-col flex-1 pt-1  bg-gradient-to-t to-[#FFFFFF1A] from-[#FFFFFF66] rounded-[14px]">
            <div onClick={() => onClickMenu('/app/dashboard')} className="sticky top-0 z-50 flex items-center  w-full py-2 px-4 border-b-2 border-[#A9A9AA]/20 cursor-pointer">
              <div className="">
                <img className="w-20 h-20" src="/img/witn.png" alt="gcn.png" />
              </div>
              <div>
                <p className="text-[#FFFFFF] text-[24px] font-[700]">VuneCoin</p>
              </div>
            </div>
            <nav className="flex-1 w-full px-5 py-4 space-y-4">
              <div onClick={() => onClickMenu('/app/dashboard')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg cursor-pointer   hover:all-btn-color transition-all duration-300 ${location.pathname === "/app/dashboard" ? " text-white	 bg-[#6514A6] rounded-lg" : " xl:text-[#898CAF] text-white"}`}>
                <RiDashboardFill className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">Dashboard</span>
              </div>
              <div onClick={() => onClickMenu('/app/transactions')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg  cursor-pointer  hover:all-btn-color ${location.pathname === "/app/transactions" ? "text-white	 bg-[#6514A6]  rounded-lg" : "xl:text-[#898CAF] text-white"}`}>
                <TbTransform className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">Transactions</span>
              </div>

              <div onClick={() => onClickMenu('/app/profile')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg  cursor-pointer  hover:all-btn-color ${location.pathname === "/app/profile" ? "text-white	 bg-[#6514A6]  rounded-lg" : "xl:text-[#898CAF] text-white"}`}>
                <TbUserCog className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">Profile</span>
              </div>

              <div onClick={() => onClickMenu('/app/changePassword')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg  cursor-pointer  hover:all-btn-color ${location.pathname === "/app/changePassword" ? "text-white	 bg-[#6514A6]  rounded-lg" : "xl:text-[#898CAF] text-white"}`}>
                <RiLockPasswordLine className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">Security</span>
              </div>
              <div onClick={() => onClickMenu('/app/notification')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg  cursor-pointer  hover:all-btn-color ${location.pathname === "/app/notification" ? "text-white	 bg-[#6514A6]  rounded-lg" : "xl:text-[#898CAF] text-white"}`}>
                <MdNotificationsNone className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">Notification</span>
              </div>

              <div onClick={() => onClickMenu('/app/news')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg  cursor-pointer  hover:all-btn-color ${location.pathname === "/app/news" ? "text-white	 bg-[#6514A6]  rounded-lg" : "xl:text-[#898CAF] text-white"}`}>
                <GiNewspaper className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">News</span>
              </div>

              <div onClick={() => onClickMenu('/app/video')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg  cursor-pointer  hover:all-btn-color ${location.pathname === "/app/video" ? "text-white	 bg-[#6514A6]  rounded-lg" : "xl:text-[#898CAF] text-white"}`}>
                <PiVideoDuotone className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">Video</span>
              </div>


              <div onClick={() => onClickMenu('/app/support')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg  cursor-pointer  hover:all-btn-color ${location.pathname === "/app/support" ? "text-white	 bg-[#6514A6]  rounded-lg" : "xl:text-[#898CAF] text-white"}`}>
                <MdSupportAgent className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">Contact Us</span>
              </div>

              <div onClick={logoutSubmit} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg  cursor-pointer  hover:all-btn-color ${location.pathname === "/app/login" ? "text-white	 bg-[#6514A6]  rounded-lg" : "xl:text-[#898CAF] text-white"}`}>
                <AiOutlineLogout className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">Logout</span>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );

}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting, user } = overview ? overview : {};
  return {
    users,
    setting,
    user
  };
}


export default (connect(mapStateToProps)(withRouter(Sidebar)));
