import React, { Suspense, lazy } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import Sidebar from '../Sidebar'
import Header from '../Header'

const securityQuestion = lazy(() =>
  import('../../pages/securityQuestion/securityQuestion')
)
const Logout = lazy(() => import('../../pages/Logout/Logout'))
const Wallet = lazy(() => import('../../pages/Wallet/Wallet'))
const Dashboard = lazy(() => import('../../pages/dashboard'))
const Profile = lazy(() => import('../../pages/profile'))
const Referral = lazy(() => import('../../pages/referral'))
const Security = lazy(() => import('../../pages/security'))
const Transactions = lazy(() => import('../../pages/transactions'))
const CommingSoon = lazy(() => import('../commingSoon'))
const Support = lazy(() => import('../../pages/support'))
const News = lazy(() => import('../../pages/news/News'))
const Contactus = lazy(() => import('../../pages/contactus'))
const ReferalTable = lazy(() =>
  import('../../pages/dashboard/components/referalTable/referalTable')
)
const ChangePassword = lazy(() =>
  import('../../pages/changePassword/changePassword')
)
const SwapCoin = lazy(() => import('../../pages/SwapCoin/SwapCoin'))
const KYC = lazy(() => import('../../pages/KYC/KYC'))
const BuyCoin = lazy(() => import('../../pages/buyCoin/buyCoin'))
const Send = lazy(() => import('../../pages/Send/Send'))
const Payment = lazy(() => import('../../pages/Payment/Payment'))
const AddContract = lazy(() => import('../../pages/AddContract/AddContract'))
const Notification = lazy(() => import('../../pages/notification/notification'))
const Video = lazy(() => import('../../pages/Video/Video'))
const Videos = lazy(() => import('../../pages/Video/Videos'))

function Layout (props) {
  const currentPath = props.location.pathname.split('/')
  const dashboard = currentPath[2]

  return (
    <div className='flex h-screen overflow-hidden bg-[#1C2745]'>
      <Sidebar />
      <div className='flex flex-col flex-1 w-full overflow-hidden h-screen '>
        <Header history={props.history} currentPath={dashboard} name='' />
        <Suspense
          fallback={
            <div className='flex justify-center items-center h-full'>
              Loading...
            </div>
          }
        >
          <Switch>
            <Route
              path='/app/referalTable/:refId?'
              component={withRouter(ReferalTable)}
            />
            <Route
              path='/app/changePassword'
              component={withRouter(ChangePassword)}
            />
            <Route path='/contactus' component={withRouter(Contactus)} />
            <Route
              path='/app/dashboard/:walletName?/:address?'
              component={withRouter(Dashboard)}
            />
            <Route path='/app/wallet' component={withRouter(Wallet)} />
            <Route path='/app/buyCoin' component={withRouter(BuyCoin)} />
            <Route path='/app/profile' component={withRouter(Profile)} />
            <Route path='/app/referral' component={withRouter(Referral)} />
            <Route path='/app/security' component={withRouter(Security)} />
            <Route
              path='/app/SecurityQuestion'
              component={withRouter(securityQuestion)}
            />
            <Route
              path='/app/Send/:address?/:contractAddr?'
              component={withRouter(Send)}
            />
            <Route path='/app/support' component={withRouter(Support)} />
            <Route path='/app/news' component={withRouter(News)} />
            <Route path='/app/video' component={withRouter(Video)} />
            <Route path='/app/videos/:id' component={withRouter(Videos)} />
            <Route path='/app/swapCoin' component={withRouter(SwapCoin)} />
            <Route path='/app/KYC' component={withRouter(KYC)} />
            <Route
              path='/app/transactions'
              component={withRouter(Transactions)}
            />
            <Route
              path='/app/commingSoon'
              component={withRouter(CommingSoon)}
            />
            <Route
              path='/app/Payment/:address?'
              component={withRouter(Payment)}
            />
            <Route
              path='/app/AddContract/:address?'
              component={withRouter(AddContract)}
            />
            <Route
              path='/app/notification'
              component={withRouter(Notification)}
            />
            <Route path='/app/logout' component={withRouter(Logout)} />
          </Switch>
        </Suspense>
      </div>
    </div>
  )
}

export default withRouter(Layout)
