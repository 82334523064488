export const CONST = {

  // BACKEND_URL: 'https://leytia-wallet-backend.vercel.app/api/v1',
  BACKEND_URL: 'https://wallet.vunecoin.com/api/v1',
  // BACKEND_URL: 'http://192.168.1.9:5204/api/v1',
  // BACKEND_URL: 'http://localhost:5204/api/v1',
  // BACKEND_URL: 'https://gcn-wallet-backend.vercel.app/api/v1',


};
