import React, { Component } from 'react'
import { connect } from 'react-redux'
import { userActions } from '../../_actions'
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { Menu } from '@headlessui/react'
import { MdKeyboardArrowDown, MdNotificationsNone } from 'react-icons/md'
import { withRouter } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { AiOutlineLogout } from 'react-icons/ai'

class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeTab: false,
      notificationCount: props.users?.getProfile?.notificationCount || ''
    }
  }

  logout = url => {
    window.sessionStorage.removeItem('user')
    this.props.history.push(url)
    setTimeout(() => {}, 150)
  }

  componentDidMount () {
    this.props.dispatch(userActions.getProfile())
  }

  logoutSubmit = () => {
    confirmAlert({
      title: 'Are you sure you want to logout?',
      buttons: [
        {
          label: 'Logout',
          onClick: () => this.logout('/login')
        },
        {
          label: 'Cancel'
        }
      ],
      overlayClassName: 'custom-overlay'
    })
  }

  classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  onClickMenu = url => {
    this.props.history.push(url)
    console.log('this.props____:', this.props)
  }

  handleOpen = () => {
    this.setState({ activeTab: true })
  }

  handleFalse = () => {
    this.setState({ activeTab: false })
  }

  handleClearNotification = () => {
    this.setState({ notificationCount: '' })
  }

  render () {
    let { users } = this.props
    let { getProfile } = users
    const imageSrc = getProfile && getProfile.user && getProfile.user.profileUrl
    console.log(
      'getProfileDataPigetProfileDataPigetProfileDataPi',
      this.state.notificationCount
    )

    return (
      <>
        <div className='sticky top-0 border-b border-[#d2d2d2] z-10 h-16 '>
          <div className='flex items-center justify-between px-4 py-2'>
            <div className='w-full'></div>
            <div className='flex items-center justify-end py-1 w-full'>
              <div onClick={this.handleClearNotification}>
                <button
                  onClick={() => this.onClickMenu('/app/notification')}
                  class='relative bg-[#6514A6] shadow-lg duration-300 p-0.5 text-blue-100 rounded-full mt-0.5'
                >
                  <MdNotificationsNone className='w-8 h-8 text-[#fff] relative' />
                  {this.state.notificationCount ? (
                    <span className='absolute -top-2 left-6 bg-white flex justify-center items-center text-xs rounded-full h-6 w-6 text-red-500'>
                      {' '}
                      {this.state.notificationCount || ''}
                    </span>
                  ) : (
                    ''
                  )}
                </button>
              </div>
              <Menu
                as='div'
                className='relative flex items-center space-x-1 text-left lg:space-x-4'
              >
                {({ open }) => (
                  <>
                    <div className='relative pr-2 lg:ml-6'>
                      <Menu.Button className='flex items-center max-w-xs text-sm rounded-full lg:space-x-1 focus:outline-none focus:shadow-outline'>
                        {imageSrc ? (
                          <img
                            className='w-12 h-12 rounded-full'
                            src={
                              getProfile &&
                              getProfile.user &&
                              getProfile.user.profileUrl
                                ? getProfile.user.profileUrl
                                : ''
                            }
                            alt={
                              getProfile &&
                              getProfile.user &&
                              getProfile.user.image
                                ? getProfile.user.image
                                : ''
                            }
                          />
                        ) : (
                          <img src='/img/Image.png' alt='profile' />
                        )}
                        <p
                          className='text-[#FFFFFF] text-[10px] font-[500]'
                          translate='no'
                        >
                          {getProfile &&
                          getProfile.user &&
                          getProfile.user.firstName
                            ? getProfile.user.firstName
                            : ''}{' '}
                          {getProfile &&
                          getProfile.user &&
                          getProfile.user.lastName
                            ? getProfile.user.lastName
                            : ''}
                        </p>
                        <MdKeyboardArrowDown
                          className='text-[#ffff]'
                          size={25}
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <Menu.Items
                        static
                        className='absolute right-0 mt-2 font-medium origin-top-right bg-white rounded-md w-44 top-8 shadow-3xl ring-1 ring-black ring-opacity-5 focus:outline-none'
                      >
                        <div className='py-1'>
                          <Menu.Item>
                            {({ active }) => (
                              <span
                                onClick={() => this.logoutSubmit('/login')}
                                className={this.classNames(
                                  active
                                    ? 'bg-red-500 text-red-500 hover:bg-red-400 hover:text-white'
                                    : 'text-red-500 ',
                                  'transition ease-in-out duration-500 cursor-pointer px-4 py-2 font-semibold text-sm flex items-center'
                                )}
                              >
                                <span className='pr-3'>
                                  <AiOutlineLogout className='w-5 h-5' />
                                </span>{' '}
                                Logout
                              </span>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps (state) {
  const { loggingIn, user, otpSent } = state.authentication
  const { users } = state

  return {
    loggingIn,
    otpSent,
    user,
    users
  }
}

export default withRouter(connect(mapStateToProps)(Header))
