/* eslint-disable eqeqeq */

import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import { MdAddCall } from "react-icons/md";
import { AiOutlineMail } from 'react-icons/ai';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Timer from 'otp-timer';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldslogin: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        mobNo: "",
        confirmPassword: "",
        OTP: "",
        countryCode: "",
        flag: '',
      },
      errorslogin: {},
      otpSentRegister: false,
      showPassword: false,
      showConfirmPassword: false,
      selectedCountry: "",
      diaCode: ""
    };
    this.inputChange = this.inputChange.bind(this);
    this.registerSubmit = this.registerSubmit.bind(this);
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.toggleConfirmPasswordVisibility = this.toggleConfirmPasswordVisibility.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(userActions.getCountryCodeList());
  }

  inputChange(e) {
    const { users } = this.props;
    const { countryCodeList } = users
    const { name, value } = e.target;
    if (name === "countryCode") {


      console.log("countryCodeList", countryCodeList)
      let filteredData = countryCodeList.filter(item => item.id === value);
      console.log(filteredData);

      let datacountrycodeexample = filteredData && filteredData[0].code

      this.setState({ flag: datacountrycodeexample })








      console.log("valuevaluevaluevalue", value)
      let value2 = filteredData && filteredData[0].dialcode

      this.setState({ diaCode: value2 });
    }
    this.setState(prevState => ({
      fieldslogin: {
        ...prevState.fieldslogin,
        [name]: value
      }
    }));
  }

  registerSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      const { email, password, mobNo, confirmPassword, firstName, lastName, countryCode } = this.state.fieldslogin;
      // const diledata = this.state.diaCode;

      const data = {
        firstName,
        lastName,
        email,
        password,
        countryCode,
        mobNo,
        confirmPassword,
        flag: this.state.flag
      };
      console.log('data__________________', data);
      this.props.dispatch(userActions.register(data, this.props));
    }
  }

  resendOtpSubmit = () => {
    // e.preventDefault();
    if (this.handleValidationLogin()) {
      const { email, password, mobNo, confirmPassword, countryCode, firstName, lastName, } = this.state.fieldslogin;
      const data = {
        firstName,
        lastName,
        email,
        password,
        countryCode,
        mobNo,
        confirmPassword,
      };

      this.setState(prevState => ({
        fieldslogin: {
          ...prevState.fieldslogin,
          OTP: ""
        }
      }));



      console.log('data____________________________', data);
      this.props.dispatch(userActions.register(data, this.props));





    }
  }

  otpSubmit = (e) => {
    e.preventDefault();
    if (this.validationOtp()) {
      let { token } = this.props.registeruser;
      let { OTP } = this.state.fieldslogin;
      this.props.dispatch(userActions.registerValidateOtp({
        token: token,
        otp: OTP
      }, this.props));
    }
  }

  validationOtp() {
    const { OTP } = this.state.fieldslogin;
    const errorslogin = {};

    // OTP validation
    const OTPPattern = /^\d{6}$/;
    if (!OTP || !OTP.match(OTPPattern)) {
      errorslogin["OTP"] = "OTP must be 6 digits.";
    }
    this.setState({ errorslogin });
    return Object.keys(errorslogin).length === 0;
  }

  handleValidationLogin() {
    const { email, password, confirmPassword, mobNo, countryCode, firstName, lastName } = this.state.fieldslogin;
    const errorslogin = {};

    // Email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!email || !emailPattern.test(email)) {
      errorslogin["email"] = "Enter a valid email address.";
    }

    // mobNo validation   
    const mobNoPattern = /^\d{7,15}$/;
    if (!mobNo || !mobNo.match(mobNoPattern)) {
      errorslogin["mobNo"] = "Mobile number must be 7 to 15 digits.";
    }

    // Password validation
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$#!%*?&]{8,15}$/;
    if (!password || !password.match(passwordPattern)) {
      errorslogin["password"] = "Password length should be 8 to 15 characters, contain at least one uppercase letter, one lowercase letter, one number, and one special character.";
    }

    // Confirm Password validation
    if (!confirmPassword || confirmPassword == "") {
      errorslogin["confirmPassword"] = "Enter confirm password";
    }
    if (password !== confirmPassword) {
      errorslogin["confirmPassword"] = "Password and Confirm password must be same.";
    }

    if (firstName.trim() === "") {
      errorslogin["firstName"] = "Required first name";
    }

    if (lastName.trim() === "") {
      errorslogin["lastName"] = "Required last name";
    }

    if (countryCode === "") {
      errorslogin["countryCode"] = "Required country code";
    }

    this.setState({ errorslogin });
    return Object.keys(errorslogin).length === 0;
  }


  onClickMenu = (url) => {
    this.props.history.push(url)
  }


  togglePasswordVisibility() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }));
  }

  toggleConfirmPasswordVisibility() {
    this.setState(prevState => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  }

  handleKeyPress = (event) => {
    const keyPressed = event.key.toLowerCase();
    const country = this.props.users.countryCodeList.find(
      (element) => element && element.name.toLowerCase().startsWith(keyPressed)
    );
    console.log('country____________', country);
    if (country) {
      this.setState(country);
    }
  };

  render() {
    const { otpSentRegister, loading, users } = this.props;
    const { countryCodeList } = users
    console.log(this.props)
    // const { errorslogin } = this.state;
    console.log('countryCodeList________________________________', countryCodeList)

    // let { countryCodeList } = users;

    return (
      <div>
        <LoadingOverlay
          active={loading}
          className="temp001"
          spinner
          text='Please wait...' />

        <section className="flex items-center w-full">
          <div className="bg-gradient-to-t to-[#5B4DCA] from-[#2D2664] w-full relative lg:block hidden">
            <img className="absolute top-0 left-0" src="/img/Vector.png" alt="" />
            <div className="flex flex-col justify-center items-center h-screen">
              <div>
                <img src="/img/imgpsh_fullsize_anim.png" className=" w-72 h-72" alt="" />
              </div>
              <div className="border-t border-[#FFFFFF4D] py-6">
                <h1 className="text-white xl:text-[60px] text-[40px] font-[700] text-center">Welcome to VuneCoin</h1>
                <p className="text-[#FFFFFFA1]/60 text-center xl:text-[26px] text-[20px] font-[400]">To keep connect with us please <br /> register with your personal information</p>
              </div>
              <div className="flex justify-center items-center absolute bottom-4">
                <a href="https://vuneco.com/" target="_blank" rel="noreferrer">
                  <img src="/img/product-icon.png" alt="" />
                </a>
              </div>
            </div>
            <img className="absolute bottom-0 right-0" src="/img/Vector-1.png" alt="" />
          </div>
          <div className="bg-[#1C2745] w-full overflow-y-auto h-screen overflow-hidden">
            <div className="flex-col justify-center  flex w-5/6 mx-auto space-y-2 xl:w-3/6 h-screen">
              <div className="text-center">
                <h1 className="xl:text-[45px] text-[40px] font-[700] text-white">Register</h1>
                <p className="xl:text-[16px] text-[14px] font-[400] text-[#FFFFFFA6]">Register for your Vune wallet.
                  So that you can receive and send VuneCoin</p>
              </div>
              <div className="flex space-x-2">
                <div className="space-y-2 ">
                  <label className="text-sm font-medium text-white">First name</label>
                  <div className="relative flex items-center">
                    {/* <AiOutlineMail className="absolute right-3 text-gray-400" /> */}
                    <input disabled={otpSentRegister ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal  placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-white ${!this.state.errorslogin["firstName"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="firstName" name="firstName" placeholder="First name" type="firstName" onChange={this.inputChange} />
                  </div>
                  {this.state.errorslogin["firstName"] ? <div className="mt-1 font-serif text-red-500 invalid-feedback xl:text-[14px] text-[10px]">{this.state.errorslogin["firstName"]}</div> : null}
                </div>
                <div className="space-y-2 ">
                  <label className="text-sm font-medium text-white">Last name</label>
                  <div className="relative flex items-center">
                    {/* <AiOutlineMail className="absolute right-3 text-gray-400" /> */}
                    <input disabled={otpSentRegister ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal  placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-white ${!this.state.errorslogin["lastName"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="lastName" name="lastName" placeholder="Last name" type="lastName" onChange={this.inputChange} />
                  </div>
                  {this.state.errorslogin["lastName"] ? <div className="mt-1 font-serif text-red-500 invalid-feedback xl:text-[14px] text-[10px]">{this.state.errorslogin["lastName"]}</div> : null}
                </div>
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium text-white">Email address</label>
                <div className="relative flex items-center">
                  <AiOutlineMail className="absolute right-3 text-gray-400" />
                  <input disabled={otpSentRegister ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal  placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-white ${!this.state.errorslogin["email"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="email" name="email" placeholder="example@gmail.com" type="email" onChange={this.inputChange} />
                </div>
                {this.state.errorslogin["email"] ? <div className="mt-1 font-serif text-red-500 invalid-feedback xl:text-[14px] text-[10px]">{this.state.errorslogin["email"]}</div> : null}
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-white">Mobile number</label>
                <div className="relative flex items-center space-x-1">
                  <select className={`border px-2 py-[11px] w-28 rounded-l-md focus:outline-none text-base font-normal  placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-white ${!this.state.errorslogin["countryCode"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="countryCode" name="countryCode"
                    onChange={this.inputChange} onKeyDown={this.handleKeyPress}>
                    <option className="bg-transparent text-black" selected>Country</option>
                    {
                      countryCodeList && countryCodeList.length > 0 ?
                        countryCodeList.map((element) => (


                          <option className="bg-transparent text-black" value={element && element.id ? element.id : null}>{element && element.name ? element.name : "NA"}  {element && element.emoji ? element.emoji : "NA"}</option>
                        )) : null
                    }
                  </select>

                  <MdAddCall className="absolute right-3 text-gray-400" />
                  <span className="absolute left-[8rem] text-[#fff]">
                    {this.state.diaCode}
                  </span>
                  <input disabled={otpSentRegister ? true : false} maxLength={15} className={`w-full border pl-16 py-2.5 rounded-r-md focus:outline-none text-base font-normal  placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-white ${!this.state.errorslogin["mobNo"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="mobNo" name="mobNo" placeholder="Mobile number" type="text" onChange={this.inputChange} onKeyPress={(e) => {
                    const isNumeric = /^[0-9]*$/;
                    if (!isNumeric.test(e.key)) {
                      e.preventDefault();
                    }
                  }} />
                </div>
                <div className="flex space-x-3">
                  {this.state.errorslogin["countryCode"] ? <div className="mt-1 font-serif text-red-500 invalid-feedback xl:text-[14px] text-[10px]">{this.state.errorslogin["countryCode"]}</div> : null}
                  {this.state.errorslogin["mobNo"] ? <div className="mt-1 font-serif text-red-500 invalid-feedback xl:text-[14px] text-[10px]">{this.state.errorslogin["mobNo"]}</div> : null}
                </div>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-white">Password</label>
                <div className="relative flex items-center">
                  <input disabled={otpSentRegister ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-white placeholder-white placeholder:text-[#c1bfe9] bg-transparent ${!this.state.errorslogin["password"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="password" name="password" placeholder="Password" type={this.state.showPassword ? "text" : "password"} onChange={this.inputChange} onCopy={(e) => { e.preventDefault(); alert("Copying password is not allowed."); }} />
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                    {this.state.showPassword ? <FaEye className="text-gray-400 cursor-pointer" onClick={this.togglePasswordVisibility} /> : <FaEyeSlash className="text-gray-400 cursor-pointer" onClick={this.togglePasswordVisibility} />}
                  </div>
                </div>
                {this.state.errorslogin["password"] ? <div className="mt-1 font-serif text-red-500 invalid-feedback xl:text-[14px] text-[10px]">{this.state.errorslogin["password"]}</div> : null}
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-white">Confirm password</label>
                <div className="relative flex items-center">
                  <input disabled={otpSentRegister ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-white placeholder-white placeholder:text-[#c1bfe9] bg-transparent ${!this.state.errorslogin["confirmPassword"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="confirmPassword" name="confirmPassword" placeholder="Confirm password" type={this.state.showConfirmPassword ? "text" : "password"} onChange={this.inputChange} onCopy={(e) => { e.preventDefault(); alert("Copying password is not allowed."); }} />
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                    {this.state.showConfirmPassword ? <FaEye className="text-gray-400 cursor-pointer" onClick={this.toggleConfirmPasswordVisibility} /> : <FaEyeSlash className="text-gray-400 cursor-pointer" onClick={this.toggleConfirmPasswordVisibility} />}
                  </div>
                </div>
                {this.state.errorslogin["confirmPassword"] ? <div className="mt-1 font-serif text-red-500 invalid-feedback xl:text-[14px] text-[10px]">{this.state.errorslogin["confirmPassword"]}</div> : null}
              </div>

              {
                otpSentRegister ?
                  <div className="">
                    <div className="">
                      <label className="text-sm font-medium text-white">Access Code</label>
                    </div>
                    <input className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-white placeholder-white placeholder:text-[#c1bfe9] bg-transparent  ${!this.state.errorslogin["OTP"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="OTP" name="OTP"
                      value={this.state.fieldslogin["OTP"] ? this.state.fieldslogin["OTP"] : ''}
                      onChange={this.inputChange} placeholder="Enter access code" type="text"
                    />
                    {this.state.errorslogin["OTP"] ?
                      <div className="w-5/6 text-red-500">
                        {this.state.errorslogin["OTP"]}
                      </div>
                      : null}
                  </div>
                  : null
              }


              {otpSentRegister ?
                <div className="flex items-center justify-between">
                  <div className="">
                    <Timer textColor={"white"} seconds={60} buttonColor="#FFCD2E" background="none" ButtonText="Resend Access Code" resend={this.resendOtpSubmit} />
                  </div>
                </div> : null}

              {otpSentRegister ?
                <div className=" ">
                  <button className="w-full py-3 mx-auto text-white transition duration-150 ease-in-out rounded-[8px] cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline" type="button" onClick={this.otpSubmit} >Register</button>
                </div>

                :
                <div className="">
                  <button className="w-full py-3 mx-auto text-white transition duration-150 ease-in-out rounded-[8px] cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline" type="submit" onClick={this.registerSubmit}>Register</button>
                </div>
              }
              <div className="py-0">
                <p className="text-center text-[18px] font-[400] text-[#A09DC5]">Already have an account? <span onClick={() => this.onClickMenu('/login')} className="text-[#508EF3] cursor-pointer">Sign in</span></p>
              </div>
            </div>

          </div>
        </section>

      </div>
    )
  }
}

function mapStateToProps(state) {
  const { otpSentRegister, registeruser, loading } = state.authentication;
  const { users } = state;
  return {
    otpSentRegister,
    registeruser,
    users,
    loading
  };
}

export default connect(mapStateToProps)(Signup);
